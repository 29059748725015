<template>
  <b-modal ref="modal" centered :title="modalTitle" hide-footer @hide="resetForm">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleAddEditBillOfLanding)">
        <b-form-group label="الشركة" label-for="name-input">
          <ValidationProvider name="الشركة" rules="required" v-slot="{ errors }">
            <v-select
              v-model="billOfLanding.companyId"
              :options="companies"
              :reduce="(comp) => comp.id"
              :getOptionLabel="(comp) => comp.userDisplayName"
              :class="{ 'is-invalid': errors.length > 0 }"
              :state="errors.length === 0 ? null : false"
              dir="rtl"
              placeholder="اختر الشركة"
            ></v-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="كومنت الشركة" label-for="name-input">
          <ValidationProvider name="كومنت الشركة" rules="required" v-slot="{ errors }">
            <v-select
              v-model="billOfLanding.approvedStatus"
              :options="approveStatus"
              :reduce="(s) => s.id"
              :getOptionLabel="(s) => s.name"
              :class="{ 'is-invalid': errors.length > 0 }"
              :state="errors.length === 0 ? null : false"
              dir="rtl"
              placeholder="اختر كومنت الشركة"
            ></v-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>

        <b-form-group label="رقم البوليصة الكلية" label-for="name-input">
          <ValidationProvider name="رقم البوليصة الكلية" rules="required" v-slot="{ errors }">
            <b-form-input
              id="totalBillOfLandingNumber-input"
              v-model="billOfLanding.totalBillOfLandingNumber"
              :state="errors.length === 0 ? null : false"
            ></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="رقم البوليصة الفرعية" label-for="name-input">
          <ValidationProvider name="رقم البوليصة الفرعية" rules="required" v-slot="{ errors }">
            <b-form-input
              id="subBillOfLandingNumber-input"
              v-model="billOfLanding.subBillOfLandingNumber"
              :state="errors.length === 0 ? null : false"
            ></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="طلب استلام البوليصة" label-for="name-input">
          <ValidationProvider name="طلب استلام البوليصة" rules="required" v-slot="{ errors }">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="ar"
              size="lg"
              v-model="billOfLanding.withdrawalRequestDate"
              :state="errors.length === 0 ? null : false"
              placeholder=""
            ></b-form-datepicker>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <footer class="text-right modal-footer">
          <b-button type="submit" variant="primary">{{ isEditMode ? 'تعديل' : 'اضافة' }}</b-button>
          <b-button type="button" variant="secondary" @click="hideModal">الغاء</b-button>
        </footer>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { BFormGroup, BFormInput, BFormTextarea, BButton, BModal, BFormInvalidFeedback, BFormDatepicker, BInputGroup } from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required, min, min_value, numeric } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BModal,
    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,
    BInputGroup
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    initialBillOfLanding: {
      type: Object,
      default: () => ({
        id: null,
        companyId: '',
        totalBillOfLandingNumber: '',
        subBillOfLandingNumber: '',
        withdrawalRequestDate: null,
        status: null,
        approvedStatus: null
      })
    },
    modalTitle: {
      type: String,
      default: 'اضافة بوليصة'
    }
  },
  data() {
    return {
      approveStatus: [
        { id: 0, name: 'الاحتفاظ بها' },
        { id: 1, name: 'الاحتفاظ بها وارسلها للشركة' },
        { id: 2, name: 'الاحتفاظ بها والعمل عليها' }
      ],
      locale: 'ar',
      billOfLanding: { ...this.initialBillOfLanding }
    }
  },
  async mounted() {
    // Switch to Arabic in validation
    localize(this.locale)
    await this.fetchCompanies()
  },
  computed: {
    ...mapGetters('invoices', { companies: 'getCompanies' })
  },
  watch: {
    initialBillOfLanding(newBillOfLanding) {
      this.billOfLanding = { ...newBillOfLanding }
    }
  },
  methods: {
    ...mapActions('billOfLandings', ['createBillOfLanding', 'updateBillOfLanding']),
    ...mapActions('invoices', { fetchCompanies: 'fetchCompanies' }),

    showModal() {
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },

    handleAddEditBillOfLanding() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          this.billOfLanding.companyName = this.companies.find((comp) => comp.id === this.billOfLanding.companyId).userDisplayName
          const action = this.isEditMode ? 'updateBillOfLanding' : 'createBillOfLanding'
          this[action](this.billOfLanding).then(() => {
            this.$emit('billOfLanding-saved', this.billOfLanding)
            this.$refs.modal.hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم التنفيذ بنجاح',
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'خطأ في السيرفر',
              icon: 'TrashIcon',
              variant: 'danger'
            }
          })
        }
      })
    },
    resetForm() {
      this.billOfLanding = { ...this.initialBillOfLanding }
    }
  }
}
</script>

<style scoped>
::v-deep .modal-title {
  font-size: 1.6rem;
  font-weight: 400;
}
::v-deep .dropdown-menu {
  width: 270px;
}
/* Add your custom styles here */
</style>
