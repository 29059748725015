var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"centered":"","title":_vm.modalTitle,"hide-footer":""},on:{"hide":_vm.resetForm}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleAddEditBillOfLanding)}}},[_c('b-form-group',{attrs:{"label":"الشركة","label-for":"name-input"}},[_c('ValidationProvider',{attrs:{"name":"الشركة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.companies,"reduce":function (comp) { return comp.id; },"getOptionLabel":function (comp) { return comp.userDisplayName; },"state":errors.length === 0 ? null : false,"dir":"rtl","placeholder":"اختر الشركة"},model:{value:(_vm.billOfLanding.companyId),callback:function ($$v) {_vm.$set(_vm.billOfLanding, "companyId", $$v)},expression:"billOfLanding.companyId"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"كومنت الشركة","label-for":"name-input"}},[_c('ValidationProvider',{attrs:{"name":"كومنت الشركة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.approveStatus,"reduce":function (s) { return s.id; },"getOptionLabel":function (s) { return s.name; },"state":errors.length === 0 ? null : false,"dir":"rtl","placeholder":"اختر كومنت الشركة"},model:{value:(_vm.billOfLanding.approvedStatus),callback:function ($$v) {_vm.$set(_vm.billOfLanding, "approvedStatus", $$v)},expression:"billOfLanding.approvedStatus"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"رقم البوليصة الكلية","label-for":"name-input"}},[_c('ValidationProvider',{attrs:{"name":"رقم البوليصة الكلية","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"totalBillOfLandingNumber-input","state":errors.length === 0 ? null : false},model:{value:(_vm.billOfLanding.totalBillOfLandingNumber),callback:function ($$v) {_vm.$set(_vm.billOfLanding, "totalBillOfLandingNumber", $$v)},expression:"billOfLanding.totalBillOfLandingNumber"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"رقم البوليصة الفرعية","label-for":"name-input"}},[_c('ValidationProvider',{attrs:{"name":"رقم البوليصة الفرعية","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subBillOfLandingNumber-input","state":errors.length === 0 ? null : false},model:{value:(_vm.billOfLanding.subBillOfLandingNumber),callback:function ($$v) {_vm.$set(_vm.billOfLanding, "subBillOfLandingNumber", $$v)},expression:"billOfLanding.subBillOfLandingNumber"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"طلب استلام البوليصة","label-for":"name-input"}},[_c('ValidationProvider',{attrs:{"name":"طلب استلام البوليصة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"ar","size":"lg","state":errors.length === 0 ? null : false,"placeholder":""},model:{value:(_vm.billOfLanding.withdrawalRequestDate),callback:function ($$v) {_vm.$set(_vm.billOfLanding, "withdrawalRequestDate", $$v)},expression:"billOfLanding.withdrawalRequestDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('footer',{staticClass:"text-right modal-footer"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.isEditMode ? 'تعديل' : 'اضافة'))]),_c('b-button',{attrs:{"type":"button","variant":"secondary"},on:{"click":_vm.hideModal}},[_vm._v("الغاء")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }