<template>
  <b-modal centered ref="modal" title="استلام البوليصة" hide-footer>
    <ValidationObserver v-slot="{ invalid, handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group label="تاريخ الإستلام" label-for="receipt-date">
          <ValidationProvider name="تاريخ الإستلام" rules="required" v-slot="{ errors }">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="ar"
              size="lg"
              v-model="form.ReceiptDate"
              :state="errors.length === 0 ? null : false"
              placeholder=""
            ></b-form-datepicker>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>

        <b-form-group label="اسم المندوب" label-for="agent-select">
          <ValidationProvider name="اسم المندوب" rules="required" v-slot="{ errors }">
            <v-select
              v-model="form.AgentId"
              :options="agents"
              label="userDisplayName"
              :reduce="(agent) => agent.id"
              :state="errors.length === 0 ? null : false"
            ></v-select>
            <b-form-invalid-feedback v-if="errors.length">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>

        <b-button type="submit" class="mr-1" variant="success" :disabled="invalid"> حفظ </b-button>
        <b-button variant="danger" @click="hideModal"> إلغاء </b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { extend } from 'vee-validate'
import { required, min, min_value, numeric } from '@validations'
import { BButton, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BButton,
    BFormDatepicker
  },
  props: {
    agents: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      form: {
        ReceiptDate: '',
        AgentId: null
      }
    }
  },

  methods: {
    showModal() {
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },

    onSubmit() {
      this.$emit('submit', { ...this.form })
      // this.$emit('close') // Close the modal
    }
  }
}
</script>

<style scoped>
::v-deep .modal-title {
  font-size: 1.6rem;
  font-weight: 400;
}
::v-deep .dropdown-menu {
  width: 270px;
}
/* Add your custom styles here */
</style>
