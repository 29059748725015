<template>
  <b-card-code title="">
    <b-button style="margin-top: -60px; float: right" variant="primary" @click="showAddModal">إضافة بوليصة</b-button>
    <div class="d-flex justify-content-end pb-2">
      <b-button style="" variant="success" :disabled="invoiceDocloading" class="mr-1" @click="downloadInvoiceDocument()">
        <div v-if="!invoiceDocloading">
          البوالص لم تستلم
          <feather-icon icon="PrinterIcon" size="16" />
        </div>
        <b-spinner v-else small></b-spinner>
      </b-button>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="this.billOfLandings"
      :rtl="direction"
      :lineNumbers="true"
      :search-options="{
        enabled: false
      }"
      :paginationOptions="{
        enabled: true,
        perPage: pageSize
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'" class="text-nowrap">
          <div class="d-flex justify-content-center">
            <!-- change status by button -->
            <b-button v-if="props.row.status == 0" variant="success" class="mr-1" @click="openDialog(props.row.id)">استلام</b-button>
            <!-- <b-button
              variant="info"
              v-if="props.row.status == 0"
              class="mr-1"
              :disabled="invoiceReviewloading[props.row.id]"
              @click="changeBillStatus(props.row.id)"
            >
              <feather-icon v-if="!invoiceReviewloading[props.row.id]" icon="CheckIcon" size="16" />
              <b-spinner v-else small></b-spinner>
            </b-button> -->
            <!-- <b-button v-if="props.row.status == 0" variant="success" class="mr-1" @click="changeBillStatus(props.row.id)">سحب البوليصة</b-button> -->
            <button v-if="props.row.status == 0" class="btn btn-primary mr-1" @click="showEditModal(props.row)">
              <feather-icon icon="EditIcon" size="16" />
            </button>
            <DeleteModel :displayName="props.row.name" @confirm-delete="handleDelete(props.row.id)" />
          </div>
        </span>
        <span v-else-if="props.column.field === 'receiptDate'">
          {{ formatDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'withdrawalRequestDate'">
          {{ formatDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="badgeVariant(props.formattedRow[props.column.field])" class="text-capitalize">
            {{ badgeText(props.formattedRow[props.column.field]) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'approvedStatus'">
          {{ companyCommentText(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>
            <b-form-select v-model="pageSize" :options="['3', '7', '10', '15', '20']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <ReceiptDialog ref="receiptDialog" :agents="agents" @submit="handleFormSubmit" />
    <AddBillOfLanding
      ref="addEditBillOfLandingModal"
      :isEditMode="isEditMode"
      :initialBillOfLanding="selectedBillOfLanding"
      :modalTitle="modalTitle"
      @billOfLanding-saved="handleBillOfLandingSaved"
    />
  </b-card-code>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModel from '@core/components/DeleteModel.vue'
import AddBillOfLanding from './AddBillOfLanding.vue'
import ReceiptDialog from './ReceiptAdd.vue'

import store from '@/store'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BAvatar,
    BBadge,
    BModal,
    BCardCode,
    DeleteModel,
    VBModal,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    VueGoodTable,
    AddBillOfLanding,
    BSpinner,
    ReceiptDialog
  },
  data() {
    return {
      agents: [],
      isEditMode: false,
      invoiceReviewloading: {},
      selectedBillId: null,
      selectedBillOfLanding: {
        id: null,
        companyId: '',
        totalBillOfLandingNumber: '',
        subBillOfLandingNumber: '',
        receiptDate: null,
        withdrawalRequestDate: null,
        approvedStatus: null,
        status: null
      },
      invoiceDocloading: false,

      modalTitle: 'اضافة بوليصة',

      pageSize: 7,
      pageNumber: 1,

      dir: false,
      columns: [
        {
          label: 'اسم الشركة',
          field: 'companyName',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'تاريخ طلب استلام',
          field: 'withdrawalRequestDate',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'تاريخ استلام ',
          field: 'receiptDate',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'رقم البوليصة الكلية',
          field: 'totalBillOfLandingNumber',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'رقم البوليصة الفرعية',
          field: 'subBillOfLandingNumber',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'كومنت الشركة',
          field: 'approvedStatus',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'الحالة',
          field: 'status',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'التفاصيل',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        }
      ]
    }
  },
  async mounted() {
    await this.getBillOfLandingsGrid()
    await this.GetAgents()
  },

  methods: {
    ...mapActions('billOfLandings', ['fetchBillOfLandingsGrid', 'deleteBillOfLanding', 'changeStatus', 'openInvoiceDocument']),

    openDialog(id) {
      this.selectedBillId = id
      this.$refs.receiptDialog.showModal()
    },

    async handleFormSubmit(formData) {
      const payload = {
        Id: this.selectedBillId,
        AgentId: formData.AgentId,
        ReceiptDate: formData.ReceiptDate,
        AgentName: this.agents.find((agent) => agent.id === formData.AgentId).userDisplayName
      }

      await this.changeBillStatus(payload)
      this.$refs.receiptDialog.hideModal()
    },

    async downloadInvoiceDocument() {
      this.invoiceDocloading = true

      await this.openInvoiceDocument()
        .then(() => {
          this.invoiceDocloading = false
        })
        .catch(() => {
          this.invoiceDocloading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء فتح',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },
    async GetAgents() {
      try {
        var response = await this.$store.dispatch('GetAgents')
        if ((response.status = 200)) {
          this.agents = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async getBillOfLandingsGrid() {
      await this.fetchBillOfLandingsGrid({ pageSize: this.pageSize, pageNumber: this.pageNumber })
    },
    // async changeBillStatus(id) {
    //   this.$set(this.invoiceReviewloading, id, true)

    //   await this.changeStatus(id)
    //     .then(() => {
    //       this.$set(this.invoiceReviewloading, id, false)
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'تم تغير حالة البوليصة بنجاح',
    //           icon: 'CheckIcon',
    //           variant: 'success'
    //         }
    //       })
    //     })
    //     .catch(() => {
    //       this.$set(this.invoiceReviewloading, id, false)
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'حدث خطأ أثناء تغير حالة البوليصة',
    //           icon: 'XCircleIcon',
    //           variant: 'danger'
    //         }
    //       })
    //     })
    // },

    async changeBillStatus(payload) {
      await this.changeStatus(payload)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تغير حالة البوليصة بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء تغير حالة البوليصة',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    badgeVariant(formattedRow) {
      switch (formattedRow) {
        case 0:
          return 'warning'
        case 1:
          return 'success'
        case 2:
          return 'danger'
      }
    },
    badgeText(formattedRow) {
      switch (formattedRow) {
        case 0:
          return 'لم يتم وصول البوليصة'
        case 1:
          return 'تم سحب البوليصة'
        case 2:
          return 'تم الرفض'
      }
    },
    companyCommentText(formattedRow) {
      switch (formattedRow) {
        case 0:
          return 'الاحتفاظ بها'
        case 1:
          return 'الاحتفاظ بها وارسلها للشركة'
        case 2:
          return 'الاحتفاظ بها والعمل عليها'
      }
    },

    showAddModal() {
      this.isEditMode = false
      this.selectedBillOfLanding = {
        id: 0,
        name: '',
        price: 0,
        description: ''
      }
      this.modalTitle = 'اضافة بوليصة'
      this.$refs.addEditBillOfLandingModal.showModal()
    },
    showEditModal(billOfLanding) {
      this.isEditMode = true
      //map the selected billOfLanding to the selectedBillOfLanding and ignore the rest of the fields
      this.selectedBillOfLanding = {
        id: billOfLanding.id,
        companyId: billOfLanding.companyId,
        totalBillOfLandingNumber: billOfLanding.totalBillOfLandingNumber,
        subBillOfLandingNumber: billOfLanding.subBillOfLandingNumber,
        withdrawalRequestDate: billOfLanding.withdrawalRequestDate,
        status: billOfLanding.status,
        approvedStatus: billOfLanding.approvedStatus
      }
      this.modalTitle = 'تعديل بوليصة'
      this.$refs.addEditBillOfLandingModal.showModal()
    },

    handleBillOfLandingSaved(billOfLanding) {
      // Handle billOfLanding saved event (e.g., refresh billOfLanding list)
      // console.log('BillOfLanding saved:', billOfLanding)
    },
    async handlePageChange() {
      await this.getBillOfLandingsGrid()
    },

    async onPerPageChange(newPerPage) {
      this.pageSize = parseInt(newPerPage, 10)
      await this.getBillOfLandingsGrid(this.pageSize, this.pageNumber)
    },
    //add toster when delete billOfLanding is done and if not done show error message
    async handleDelete(id) {
      await this.deleteBillOfLanding(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'TrashIcon',
              variant: 'success'
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء الحذف',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })
    }
  },
  computed: {
    ...mapGetters('billOfLandings', {
      billOfLandings: 'getBillOfLandings',
      totalCount: 'totalCountData',
      pagesCount: 'pagesCount'
    }),

    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style>
/* Add your custom styles here */
</style>
