var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"centered":"","title":"استلام البوليصة","hide-footer":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":"تاريخ الإستلام","label-for":"receipt-date"}},[_c('ValidationProvider',{attrs:{"name":"تاريخ الإستلام","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"ar","size":"lg","state":errors.length === 0 ? null : false,"placeholder":""},model:{value:(_vm.form.ReceiptDate),callback:function ($$v) {_vm.$set(_vm.form, "ReceiptDate", $$v)},expression:"form.ReceiptDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"اسم المندوب","label-for":"agent-select"}},[_c('ValidationProvider',{attrs:{"name":"اسم المندوب","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.agents,"label":"userDisplayName","reduce":function (agent) { return agent.id; },"state":errors.length === 0 ? null : false},model:{value:(_vm.form.AgentId),callback:function ($$v) {_vm.$set(_vm.form, "AgentId", $$v)},expression:"form.AgentId"}}),(errors.length)?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"success","disabled":invalid}},[_vm._v(" حفظ ")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.hideModal}},[_vm._v(" إلغاء ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }