<template>
  <div>
    <slot name="button" :showModal="() => (this.showModal = true)">
      <!-- Default button if no slot is provided -->
      <button class="btn btn-danger"@click="showModal = true">
        <feather-icon icon="Trash2Icon" size="16" />
      </button>
      <!-- <button class="btn btn-danger" @click="showModal = true">{{ buttonText }}</button> -->
    </slot>

    <b-modal v-model="showModal"  :title="modalTitle" ok-variant="danger" ok-title="تاكيد" cancel-title="الغاء" @ok="confirmDelete" centered>
      <p>{{ modalContent }} {{ displayName }}</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'DeleteModel',
  props: {
    buttonText: {
      type: String,
      default: 'حذف'
    },
    modalTitle: {
      type: String,
      default: 'تاكيد الحذف'
    },
    modalContent: {
      type: String,
      default: 'هل انت متاكد من حذف'
    },
    displayName: {
      type: String,
      default: 'العنصر'
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    confirmDelete() {
      // Emit a 'confirm-delete' event so the parent component can handle the deletion logic
      this.$emit('confirm-delete')
    }
  }
}
</script>

<style>
/* Add your custom styles here */
</style>
